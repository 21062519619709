<template>
    <div class="other-cases">
        <router-link :to="prevRoute" class="prev-case">
            <div>{{$t('project.previous')}}</div>
            <i18n :path="prevTitle + '.title'" tag="div" class="h3" />
        </router-link>
        <router-link :to="nextRoute" class="next-case">
            <div>{{$t('project.next')}}</div>
            <i18n :path="nextTitle + '.title'" tag="div" class="h3" />
        </router-link>
    </div>
</template>
<script>
export default {
    name: 'OtherCases',
    props: {
        prevRoute: String,
        prevTitle: String,
        nextRoute: String,
        nextTitle: String
    }
}
</script>
<style lang="scss" scoped>
    .other-cases {
    border-top: 1px solid var(--color-base-moderate);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 0 !important;
    
    @include media($range-palm, $range-hand) {
        @include margin(null (-$spacing*3) 0);
    }
    @include media($range-lap, false) {
        @include margin(null (-$spacing*10) 0);
    }
}
.prev-case,
.next-case {
    display: inline-block;
    transition: background-color 300ms ease-out;

    @include media($range-palm, $range-hand) {
        @include padding($spacing*3);
    }
    @include media($range-lap, false) {
          @include padding($spacing*10);
    }

    &:hover {
        background-color: rgba(0,0,0,0.02);    

        @media screen and (prefers-color-scheme:dark) {
            background-color: rgba(255,255,255,0.03);    
        }
        
    }
}
.prev-case {
    grid-column: 1;

    .h3 {
        position: relative;

        @include media($range-palm, $range-hand) {
            font-size: var(--font-size-100);
        }

        &::before {
            content: '';
            transform: translateY(-30%) translateX(50%) rotate(45deg);
            transition: transform 0ms ease-out 300ms, opacity 150ms ease-out;
            @include position(absolute, 50% null null -24px);
            display: block;
            width: 12px;
            height: 12px;
            border-left: 1px solid var(--color-cta);
            border-bottom: 1px solid var(--color-cta);
            background-image: linear-gradient(-45deg, transparent 46%, var(--color-cta) 46%, var(--color-cta) 54%, transparent 54%);
            opacity: 0;
        }
    }
    &:hover .h3::before {
        opacity: 1;
        transform: translateY(-30%) translateX(0) rotate(45deg);
        transition: transform 300ms ease-out, opacity 300ms ease-out;
    }
}
.next-case {
    text-align: right;
    grid-column: 2;

    .h3 {
        position: relative;
        @include media($range-palm, $range-hand) {
            font-size: var(--font-size-100);
        }

        &::after {
            content: '';
            transform: translateY(-30%) translateX(-50%) rotate(45deg);
            transition: transform 0ms ease-out 300ms, opacity 150ms ease-out;
            @include position(absolute, 50% -24px null null);
            display: block;
            width: 12px;
            height: 12px;
            border-right: 1px solid var(--color-cta);
            border-top: 1px solid var(--color-cta);
            background-image: linear-gradient(-45deg, transparent 46%, var(--color-cta) 46%, var(--color-cta) 54%, transparent 54%);
            opacity: 0;
        }
    }
    &:hover .h3::after {
        opacity: 1;
        transform: translateY(-30%) translateX(0) rotate(45deg);
        transition: transform 300ms ease-out, opacity 300ms ease-out;
    }
}
</style>