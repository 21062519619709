<template>
    <div class="project">
        <div class="intro grid">
            <h1>{{$t('fastFluid.title')}}</h1>
            <div class="cell">
                <p>{{$t('fastFluid.intro')}}</p>
            </div>
            <div class="cell">
                <dl>
                    <dt>{{$t('project.client')}}</dt>
                    <dd>{{$t('fastFluid.client')}}</dd>
                    <dt>{{$t('project.at')}}</dt>
                    <dd>{{$t('fastFluid.at')}}</dd>
                    <dt>{{$t('project.role')}}</dt>
                    <dd>{{$t('fastFluid.role')}}</dd>
                    <dt>{{$t('project.timeframe')}}</dt>
                    <dd>2020</dd>
                    <dt>{{$t('project.prototype')}}</dt>
                    <dd><a href="https://xd.adobe.com/view/d6227abe-9886-4b00-4906-f2934052629e-a013/" class="button" target="_blank">Fast & Fluid design system</a></dd>
                </dl>
            </div>
        </div>
        <div class="case">
            <div class="layout">
                <div class="layout__item layout__item--12">
                    <img src="@/assets/images/cases/fast-fluid/1-cover.jpg" srcset="@/assets/images/cases/fast-fluid/1-cover@2x.jpg 2x" alt="Design System Cover">
                    <p class="caption">{{$t('fastFluid.coverImage')}}</p>
                </div>
                <div class="layout__item layout__item--12">
                    <h3>{{$t('fastFluid.typefaceTitle')}}</h3>
                    <img src="@/assets/images/cases/fast-fluid/2-typeface.jpg" srcset="@/assets/images/cases/fast-fluid/2-typeface@2x.jpg 2x" alt="Typeface selection">
                    <p class="caption">{{$t('fastFluid.typeface')}}</p>
                </div>
                <div class="layout__item layout__item--3">
                    <h3 class="h4">{{$t('fastFluid.colorsTitle')}}</h3>
                    <p>{{$t('fastFluid.colors')}}</p>
                    <h3 class="h4">{{$t('fastFluid.contrastTitle')}}</h3>
                    <p>{{$t('fastFluid.contrast')}}</p>
                </div>
                <div class="layout__item layout__item--9">
                    <img src="@/assets/images/cases/fast-fluid/3-colors.jpg" srcset="@/assets/images/cases/fast-fluid/3-colors@2x.jpg 2x" alt="Color tyles">
                </div>
                <div class="layout__item layout__item--12">
                    <h3>{{$t('fastFluid.elementsTitle')}}</h3>
                    <img src="@/assets/images/cases/fast-fluid/4-elements.jpg" srcset="@/assets/images/cases/fast-fluid/4-elements@2x.jpg 2x" alt="Form elements">
                    <p>{{$t('fastFluid.elements')}}</p>
                </div>
                <div class="layout__item layout__item--12">
                    <a href="https://xd.adobe.com/view/d6227abe-9886-4b00-4906-f2934052629e-a013/" class="button" target="_blank">Fast & Fluid design system</a>
                </div>
            </div>
        </div>
        <OtherCases prev-route="/projects/hbn" prev-title="hbn" next-route="/projects/all-united" next-title="allUnited" />
    </div>
</template>
<script>
import OtherCases from '@/components/OtherCases.vue'

export default {
    components: {
        OtherCases
    }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/_Project.scss';
</style>